import React from 'react';

import CallToAction from '../../../molecules/CallToAction/CallToAction';

import OptimizedImage from '../../../utils/OptimizedImage';

import { Heading, Wrapper } from './Tool.styles';

const Tool = ({ heading, image, link }) => (
  <Wrapper>
    <Heading>{heading}</Heading>
    <OptimizedImage image={image.image.gatsbyImageData} alt={heading} />
    {link && (
      <CallToAction variant="primary" value={link.label} link={link.url} />
    )}
  </Wrapper>
);

export default Tool;
