import styled from 'styled-components';

import { atMinWidth } from '../../styles/atoms/breakpoints';
import { padding } from '../../styles/atoms/spacing';
import shadow from '../../styles/atoms/shadows';
import { colors } from '../../styles/atoms/colors';

import { Wrapper as SectionWrapper } from '../../molecules/Heading/Heading.styles';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  background-color: ${colors.base.white};
  border-radius: 8px;
  ${padding(32)}
  ${shadow('xxl')};

  ${atMinWidth.sm`
    gap: 32px;
  `}

  ${SectionWrapper} {
    min-width: 250px;
    flex: 1 0 40%;

    ${atMinWidth.lg`
      align-items: ${props => (props.tools > 1 ? 'flex-start' : 'center')};
      text-align: ${props => (props.tools > 1 ? 'left' : 'center')};
    `}

    ${atMinWidth.xl`
      align-items: flex-start;
      text-align: left;
    `}
  }
`;

export const ToolsWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  gap: 32px;
  flex: 1 1 auto;

  ${atMinWidth.md`
    flex-direction: row;
  `}
`;
