import React from 'react';

import Heading from '../../molecules/Heading/Heading';
import Section from '../../molecules/Section/Section';

import Tool from './Tool/Tool';

import { ToolsWrapper, Wrapper } from './ToolsCallout.styles';

const ToolsCallout = ({ id, headingBlock, tools }) => (
  <Section id={id} hasWave>
    <Wrapper>
      <Heading
        {...headingBlock[0]}
        headingSize={['lg', 'lg', 'xl']}
        tools={tools.length}
      />
      <ToolsWrapper>
        {tools &&
          tools.map(tool => (
            <Tool
              key={tool.id}
              heading={tool.heading}
              link={tool.button}
              image={tool.featuredImage}
            />
          ))}
      </ToolsWrapper>
    </Wrapper>
  </Section>
);

export default ToolsCallout;
